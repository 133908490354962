.HomeViewContent {
  padding-top: 5rem;
}

@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .HomeViewContent {
    padding-top: 3rem;
  }
}

body {
  background-color: black;
}
