/* Source: https://google-webfonts-helper.herokuapp.com../fonts/roboto?subsets=latin */

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"),
    url("../fonts/roboto-v20-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/roboto-v20-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"),
    url("../fonts/roboto-v20-latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/roboto-v20-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("../fonts/roboto-v20-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/roboto-v20-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"),
    url("../fonts/roboto-v20-latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/roboto-v20-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-900 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black"), local("Roboto-Black"),
    url("../fonts/roboto-v20-latin-900.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/roboto-v20-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-900italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black Italic"), local("Roboto-BlackItalic"),
    url("../fonts/roboto-v20-latin-900italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/roboto-v20-latin-900italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-v29-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v29-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v29-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v29-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v29-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v29-latin-500.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v29-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v29-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v29-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v29-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v29-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v29-latin-700.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/roboto-v29-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v29-latin-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v29-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v29-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v29-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v29-latin-900.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
