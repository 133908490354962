:root {
  --color-text: #343434;
  --main-font: "Roboto";
  --background-green: #fafff5;
  --hover-green: #1db954;
  --background-gradient: radial-gradient(
    50% 50% at 50% 50%,
    rgba(205, 244, 220, 0.15) 21.35%,
    rgba(62, 198, 132, 0.144) 100%
  );
  --social-share-background-green: #ecfbf1;
  --social-share-text-green: #00733a;
}
