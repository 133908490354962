.playlist-text-container {
  height: 25vh;
}

@media only screen and (max-width: 600px) {
  .playlist-text-container > p {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .playlist-text-container > p {
    font-size: 1.1rem;
  }
  .playlist-text-container > h2 {
    font-size: 2rem;
    padding: 0 1rem;
  }
}

.playlist-iframe-container {
  /* padding-top: 2vh; */
  height: 45vh;
  width: 35vh;
  margin: auto;
}

.playlist-iframe {
  border-radius: 10px;
}

.share-container {
  /* height: 10vh; */
  padding-top: 5vh;
}

.playlist-sharing-text {
  font-size: 0.7rem;
}
