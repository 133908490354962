.social-share-black-text {
  color: black;
  font-weight: 500;
}

.social-share-artist-text {
  font-size: 0.65rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social-share-song-text {
  font-size: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.art-div {
  padding-right: 0;
}

.song-text-div {
  padding-left: 0.3rem;
  padding-right: 0.5rem;
}
