.content {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.rating-container {
  height: 20vh;
}

.rating-header-text {
  height: 15vh;
}
.song-iframe-container {
  height: 48vh;
  width: 38vh;
  padding: 0;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .rating-header-text > h2 {
    font-size: 1.9rem;
  }
  .rating-header-text > p {
    font-size: 1.1rem;
  }
  .song-iframe-container {
    height: 38vh;
    width: 28vh;
    padding: 0;
    margin: auto;
  }
}

/* 
.rating-header-text {
  height: 15%;
}

.rating-iframe {
  height: 50%;
}

.rating-rating {
  height: 35%;
}

.rating-container {
  height: 100vh;
  width: 100vw;
} */

/* .song-iframe-container {
  width: 40vh;
  height: 50vh;
  min-height: 20rem;
} */
