@import "../styles/variables.css";
@import "../styles/fonts.css";

body {
  font-family: var(--main-font);
  color: var(--color-text);
  /* background-color: rgb(250, 255, 245); */
  background: var(--background-gradient);
  /* background-color: #cdf4dc; */

  margin: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.header-text {
  font-size: 2.4rem;
  padding: 1rem 1rem;
}

.main-text {
  font-size: 1.3rem;
}

.social-share-green-text {
  color: var(--social-share-text-green);
}

@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .main-text {
    font-size: 1.1rem;
  }
}
