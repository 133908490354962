.canvas-element {
  max-height: 30vh;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .canvas-element {
    max-height: 25vh;
    width: 100%;
  }
}
